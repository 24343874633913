exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-candidate-profile-2-js": () => import("./../../../src/pages/candidate-profile-2.js" /* webpackChunkName: "component---src-pages-candidate-profile-2-js" */),
  "component---src-pages-candidate-profile-js": () => import("./../../../src/pages/candidate-profile.js" /* webpackChunkName: "component---src-pages-candidate-profile-js" */),
  "component---src-pages-company-profile-js": () => import("./../../../src/pages/company-profile.js" /* webpackChunkName: "component---src-pages-company-profile-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dashboard-applicants-js": () => import("./../../../src/pages/dashboard-applicants.js" /* webpackChunkName: "component---src-pages-dashboard-applicants-js" */),
  "component---src-pages-dashboard-jobs-js": () => import("./../../../src/pages/dashboard-jobs.js" /* webpackChunkName: "component---src-pages-dashboard-jobs-js" */),
  "component---src-pages-dashboard-main-js": () => import("./../../../src/pages/dashboard-main.js" /* webpackChunkName: "component---src-pages-dashboard-main-js" */),
  "component---src-pages-dashboard-settings-js": () => import("./../../../src/pages/dashboard-settings.js" /* webpackChunkName: "component---src-pages-dashboard-settings-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-details-js": () => import("./../../../src/pages/job-details.js" /* webpackChunkName: "component---src-pages-job-details-js" */),
  "component---src-pages-landing-2-js": () => import("./../../../src/pages/landing-2.js" /* webpackChunkName: "component---src-pages-landing-2-js" */),
  "component---src-pages-landing-3-js": () => import("./../../../src/pages/landing-3.js" /* webpackChunkName: "component---src-pages-landing-3-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-search-grid-js": () => import("./../../../src/pages/search-grid.js" /* webpackChunkName: "component---src-pages-search-grid-js" */),
  "component---src-pages-search-list-2-js": () => import("./../../../src/pages/search-list-2.js" /* webpackChunkName: "component---src-pages-search-list-2-js" */),
  "component---src-pages-search-list-js": () => import("./../../../src/pages/search-list.js" /* webpackChunkName: "component---src-pages-search-list-js" */)
}

